/* General Styles */
.acontainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  max-width: 1400px;
  line-height: 1.6;
}

.image-container {
  flex: 1;
  padding-right: 20px;
  margin-top: 10px;
}

.image-container img {
  max-width: 100%;
  border-radius: 8px;
  height: 30vw;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-container {
  flex: 2;
}

.section {
  margin-top: 10px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  border-left: 5px solid #0066cc;
}

.section h2 {
  color: #0066cc;
  border-bottom: 2px solid #0066cc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 1.5rem;
}

.section p {
  font-size: 1rem !important;
  color: #333;
}

/* Mission Section Specific Styles */
.mission {
  background-color: #e6f7ff;
}

/* History Section Specific Styles */
.history {
  background-color: #f7f7f7;
}

/* Leadership Section Specific Styles */
.leadership {
  background-color: #fff5e6;
}

/* What Differentiates Us Section */
.differentiate {
  background-color: #f9f9ff;
  padding: 40px 20px;
  text-align: center;
  width: 100% !important;
  margin-top: 5vw;
}

.differentiators {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100% !important;
}

.differentiator {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  flex: 1;
  text-align: center;
}

.differentiator img {
  max-width: 50px;
  margin-bottom: 10px;
}

.differentiator h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #0066cc;
}

.differentiator p {
  font-size: 14px;
  color: #555555;
}
.mutual-funds-container {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
}

.left-image {
  flex: 1;
  margin-right: 20px;
}

.left-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.right-content {
  flex: 2;
}

.right-content h2 {
  background-color: #4eaadf;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.right-content p {
  margin-top: 20px;
  line-height: 1.6;
}

.right-content h3 {
  margin-top: 30px;
  color: #b00000;
}

.right-content ol {
  margin-top: 15px;
  line-height: 1.6;
  padding-left: 20px;
}

.right-content li {
  margin-bottom: 10px;
}

.right-content strong {
  font-weight: bold;
}

/* Media Queries for Mobile Screens */
@media (max-width: 768px) {
  .mutual-funds-container {
    flex-direction: column;
    padding: 10px;
  }

  .left-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .right-content h2 {
    font-size: 1.5rem;
    padding: 8px;
  }

  .right-content p, .right-content ol {
    font-size: 0.9rem;
  }

  .right-content h3 {
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .right-content ol {
    padding-left: 15px;
  }

  .right-content li {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .right-content h2 {
    font-size: 1.3rem;
    padding: 6px;
  }

  .right-content p, .right-content ol {
    font-size: 0.8rem;
  }

  .right-content h3 {
    font-size: 1.1rem;
    margin-top: 15px;
  }

  .right-content ol {
    padding-left: 10px;
  }

  .right-content li {
    margin-bottom: 6px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .acontainer {
    flex-direction: column;
    padding: 10px;
  }

  .image-container {
    padding-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  .image-container img{
    width: 100%;
    height: auto;
  }

  .header {
    padding: 15px;
  }

  .section {
    padding: 15px;
  }

  .differentiators {
    flex-direction: column;
  }

  .differentiator {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
    font-size: 18px;
  }

  .section {
    padding: 10px;
    font-size: 16px;
  }
}
