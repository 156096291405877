/* src/components/Footer.css */
.footer {
    position: relative;
    padding: 40px 0;
    color: #fff;
    background: url('../src/images/bg.jpg') center no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  
  .footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.616); /* Adjust the color and transparency here */
    z-index: 1; /* Ensure it sits between the background and the content */
  }
  
  .footer-content {
    position: relative; /* To ensure content appears above the overlay */
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 1300px;
    margin: 0 auto;
    z-index: 2; /* Ensure content is above the overlay */
  }
  
  .footer-section {
    flex: 1;
    margin: 0 10px;
    text-align: left;
  }
  
  .footer-section h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .footer-section p {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin: 10px 0;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .cpy-right{
    z-index: 2;
  }
  
  /* Responsive Styles */
  @media (max-width: 991px) {
    .footer-content {
      flex-direction: column; /* Stack the sections vertically on medium screens */
      text-align: center;
    }
  
    .footer-section {
      margin: 10px 0; /* Adjust margin for stacked sections */
    }
  
    .footer-section ul li {
      margin: 5px 0; /* Adjust margin for list items */
    }
  
    .cpy-right {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column; /* Stack the sections vertically on smaller screens */
      text-align: center;
    }
  
    .footer-section {
      margin: 10px 0; /* Adjust margin for stacked sections */
    }
  
    .footer-section ul li {
      margin: 5px 0; /* Adjust margin for list items */
    }
  
   
  }
  
  @media (max-width: 480px) {
    .footer-section h2 {
      font-size: 16px; /* Adjust heading font size for very small screens */
    }
  
    .footer-section p {
      font-size: 12px; /* Adjust paragraph font size for very small screens */
    }
  }
  