.header-img{
    width: 100%;
    height: 20vw;
    object-fit: cover;
}

@media (max-width: 991px) {
.header-img{
    height: auto;
}
}
.s-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 4vw;
    margin-bottom: 4vw;
    font-family: 'Times New Roman', Times, serif;
}