.mutual-funds-section {
    background-color: #f9f9f9;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .fund-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .fund-card img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .fund-card h4 {
    font-size: 1.2rem;
    color: #333;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .fund-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    20%, 60% { transform: translateX(-5px); }
    40%, 80% { transform: translateX(5px); }
  }
  