/* Base styles */
.mf-directly-container {
    width: 80%;
    padding: 20px;
    background-color: #ffffff;
    margin-left: 10vw;
}

.mf-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    font-family: 'Times New Roman', Times, serif;
}

.mf-content {
    display: flex;
    gap: 20px;
}

.mf-image {
    flex: 1;
}

.mf-description {
    flex: 1;
}

.mf-description p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.highlight {
    color: #2a9d8f;
    font-weight: bold;
}

.read-more {
    display: inline-block;
    margin-top: 20px;
    font-size: 1rem;
    color: #2a9d8f;
    text-decoration: underline;
}

.invest-button {
    margin-top: 20px;
    background-color: #2a9d8f;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.invest-button:hover {
    background-color: #21867a;
}

/* Mobile styles */
@media (max-width: 768px) {
    .mf-directly-container {
        width: 90%;
        margin-left: 0;
    }

    .mf-title {
        font-size: 1.5rem;
    }

    .mf-content {
        flex-direction: column;
    }

    .mf-image, .mf-description {
        flex: none;
    }

    .mf-description p {
        font-size: 0.9rem;
    }

    .read-more, .invest-button {
        font-size: 0.9rem;
    }
}
