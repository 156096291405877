.videos-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .videos-section h2 {
    text-align: center;
    color: #07a5ba;
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .video-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .video-item {
    flex: 1 1 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .video-item:hover {
    transform: translateY(-5px);
  }
  
  .video-embed {
    margin-bottom: 15px;
  }
  
  .video-item h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .video-item p {
    color: #111111;
    font-size: 0.9rem;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .video-item {
      flex: 1 1 calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .video-item {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  